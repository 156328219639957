import EmptyLayout from "./EmptyLayout";
import Layout from "./Layout";

const LayoutConfig = [
  {
    path: "/children",
    layout: EmptyLayout,
  },
  {
    path: "/canteen/children",
    layout: EmptyLayout,
  },
  {
    path: "/wallet",
    layout: EmptyLayout,
  },
  {
    path: "/login",
    layout: EmptyLayout,
  },
  {
    path: "/terms-and-conditions",
    layout: EmptyLayout,
  },
  {
    path: "/faq/plan/ad-hoc-plan",
    layout: EmptyLayout,
  },
  {
    path: "/faq/plan/premium-lunch-5-day-plan",
    layout: EmptyLayout,
  },
  {
    path: "/faq/plan/premium-lunch-breakfast-5-day-plan",
    layout: EmptyLayout,
  },
  
];

const getLayout = (path) => {
  if (!path || path === "/") {
    return EmptyLayout;
  } else {
    let config = LayoutConfig.find((item) => path.includes(item.path));
    if (config) return config.layout;
    else return Layout;
  }
  
};

export { getLayout };
