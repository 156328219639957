import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import HeaderComponent from "../Header/HeaderComponent";
import { getParent } from "services/parents.service";
import { getAuth } from "services/identity.service";
import Loader from "atoms/Loader";
import { useRouter } from "next/router";
import { getSettings } from "services/referral.service";
import NewLoaderWithText from "molecules/NewLoaderWithText";
import Footer from "../FooterComponent/Footer";
import NewHeaderComponent from "../Header/NewHeaderComponent";
import * as R from "ramda";
import {getParentsFeedback} from "../../services/parents.service"
library.add(fas);

const Layout = ({ children }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [kidList, setKidList] = useState([]);
  const [settings, setSettings] = useState([]);
  const [saveFeedbacks, SetSaveFeedbacks] = useState([]);

  const [token, setToken] = useState("");
  const router = useRouter();
  const auth = getAuth();

 

  let isB2borSchoolB2b =
    auth?.roles?.includes("Parent Offline B2B") ||
    auth?.roles?.includes("Parent B2B2C") ||
    auth?.roles?.includes("Parent B2B");

  useEffect(async () => {
    setShowLoader(true)
    if (auth) {
      if (!auth?.roles?.includes("Parent Offline B2B") && !auth?.roles?.includes("Parent B2B2C")) {
        const res = await getParent(auth.id, auth.token);
        setToken(auth.token);
        const referralRes = await getSettings(auth.id, auth.token);

        if (referralRes?.data?.status) {
          setSettings(referralRes.data?.entity);
        }
        if (res.data.status) {
          console.log(res?.data.entity, res?.data.entity.Kids, "kids list data");
          setKidList(() => res?.data.entity.Kids);
          setShowLoader(false);
        }
      } else {
        setShowLoader(false);
        return;
      }
    } else {
      router.push("/login");
    }
  }, []);





  return (
    <>
      {showLoader === true ? (
        // <Loader />
        <div className="flex justify-center items-center h-screen">
          <NewLoaderWithText token={token}/>
        </div>
      ) : (
        <div className=" page-wrapper min-h-screen bg-gray-light pt-31">
          {!isB2borSchoolB2b ? (
            <NewHeaderComponent
              kidList={kidList}
              settings={settings}
              token={token}
            />
          ) : (
            <HeaderComponent
              kidList={kidList}
              settings={settings}
              token={token}
            />
          )}
          <div className="page-main-container relative"> {children}</div>
          <Footer />
        </div>
      )}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
